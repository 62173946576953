import SwipeListener from 'swipe-listener';

var container = document.querySelector('#main');
var listener = SwipeListener(container);

const Slides = document.getElementsByClassName("home-slide");
const subMenu = document.querySelector(".submenu-container");
const scrollDown = document.querySelector("#home .scroll-down");


// HANDLE DESKTOP DEVICES


function LoadData(Slide) {

    const pageInfo = document.querySelector(".page-info");
    const pageMarquee = document.querySelector(".marquee");
    const pageText = Slide.dataset.text;
    const pageTitle = Slide.dataset.title;
    const pageArtists = Slide.dataset.artists;
    const pageDate = Slide.dataset.date;
    const pageUrl = Slide.dataset.url;

    pageInfo.parentElement.href = pageUrl;
    pageInfo.innerHTML = "<p>" + pageText + "&nbsp;|&nbsp;" + "</>" + "<p>" + pageArtists +", "+ pageTitle + "</p><p class='page-info-date'>" + pageDate + "</p>";
    pageMarquee.innerHTML = "<div><p>" + pageText + "&nbsp;|&nbsp;" + "</>" + "<p>" + pageArtists +", "+ pageTitle + "</p><p class='page-info-date'>" + pageDate + "</p></div>";


}

const marquee = (speed) => {
    const marqueeExists = document.getElementsByClassName('marquee').length > 0;


    if (marqueeExists) {
        const selector = '.marquee';
        const parentSelector = document.querySelector(selector);
        const clone = parentSelector.innerHTML;
        const firstElement = parentSelector.children[0];
        let i = 0;
        console.log(firstElement);
        parentSelector.insertAdjacentHTML('beforeend', clone);
        parentSelector.insertAdjacentHTML('beforeend', clone);

        setInterval(function () {
            firstElement.style.marginLeft = `-${i}px`;
            if (i > firstElement.clientWidth) {
                i = 0;
            }
            i = i + speed;
        }, 0);
    }
}

document.addEventListener("DOMContentLoaded", () => {

    if (Slides.length > 0) {
        LoadData(Slides[0]);


        window.addEventListener("wheel", function (e) {

            if (subMenu.classList.contains("hide")) {

                const colorFilters = document.getElementsByClassName('color-filter');
                const salonSlide = document.getElementsByClassName('salon-slide')[0];
                const currentSlide = document.getElementsByClassName('current-slide')[0];
                const currentSlideImg = currentSlide.querySelector("img");
                const salonSlideImg = salonSlide.querySelector("img");


                if (e.deltaY < 0) {

                    Array.from(colorFilters).forEach((filter) => {
                        filter.classList.remove('in-view');
                        salonSlide.classList.remove("show");
                        scrollDown.classList.remove('revert');
                        LoadData(Slides[0]);
                        marquee(0.5);
                        salonSlideImg.classList.remove('gray');
                        currentSlideImg.classList.remove('gray');

                    });

                } else if (e.deltaY > 0) {

                    Array.from(colorFilters).forEach((filter) => {
                        filter.classList.add('in-view');
                        salonSlide.classList.add("show");
                        scrollDown.classList.add('revert');
                        LoadData(Slides[1]);
                        marquee(0.5);
                        salonSlideImg.classList.add('gray');
                        currentSlideImg.classList.add('gray');

                    });


                }

            } else {
                return false;
            }
        });
    }


// HANDLE TOUCH DEVICES


    container.addEventListener('swipe', function (e) {
        var directions = e.detail.directions;
        var x = e.detail.x;
        var y = e.detail.y;


        if (directions.top) {
            if (subMenu.classList.contains("hide")) {

                const colorFilters = document.getElementsByClassName('color-filter');
                const salonSlide = document.getElementsByClassName('salon-slide')[0];
                const currentSlide = document.getElementsByClassName('current-slide')[0];
                const currentSlideImg = currentSlide.querySelector("img");
                const salonSlideImg = salonSlide.querySelector("img");


                Array.from(colorFilters).forEach((filter) => {
                    filter.classList.add('in-view');
                    salonSlide.classList.add("show");
                    scrollDown.classList.add('revert');
                    LoadData(Slides[1]);
                    marquee(0.5);
                    salonSlideImg.classList.add('gray');
                    currentSlideImg.classList.add('gray');

                });

            } else {
                return false;
            }
        }


        if (directions.bottom) {
            const colorFilters = document.getElementsByClassName('color-filter');
            const multiply = document.getElementById('multiply');
            const salonSlide = document.getElementsByClassName('salon-slide')[0];
            const currentSlide = document.getElementsByClassName('current-slide')[0];
            const currentSlideImg = currentSlide.querySelector("img");
            const salonSlideImg = salonSlide.querySelector("img");


            Array.from(colorFilters).forEach((filter) => {
                filter.classList.remove('in-view');
                multiply.classList.remove('in-view');
                salonSlide.classList.remove("show");
                scrollDown.classList.remove('revert');
                LoadData(Slides[0]);
                marquee(0.5);
                salonSlideImg.classList.remove('gray');
                currentSlideImg.classList.remove('gray');

            });
        }


    });

    /* HANDLE CLICK ON ARROW */

    scrollDown?.addEventListener('click', function (e) {
        if (subMenu.classList.contains("hide")) {

            const colorFilters = document.getElementsByClassName('color-filter');
            const salonSlide = document.getElementsByClassName('salon-slide')[0];
            const currentSlide = document.getElementsByClassName('current-slide')[0];
            const currentSlideImg = currentSlide.querySelector("img");
            const salonSlideImg = salonSlide.querySelector("img");

            if(salonSlide.classList.contains("show")){
                Array.from(colorFilters).forEach((filter) => {
                    filter.classList.remove('in-view');
                    salonSlide.classList.remove("show");
                    scrollDown.classList.remove('revert');
                    LoadData(Slides[0]);
                    marquee(0.5);
                    salonSlideImg.classList.remove('gray');
                    currentSlideImg.classList.remove('gray');


                });
            }else{
                Array.from(colorFilters).forEach((filter) => {
                    filter.classList.add('in-view');
                    salonSlide.classList.add("show");
                    scrollDown.classList.add('revert');
                    LoadData(Slides[1]);
                    marquee(0.5);
                    salonSlideImg.classList.add('gray');
                    currentSlideImg.classList.add('gray');

                });
            }


        }
    });

});